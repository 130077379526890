/* eslint-disable react/prop-types */
import React from 'react'
import styled from '@style'
import { Icon } from '@iconify/react'

import { Flex } from '@components/Grid'
import { Text } from '@components/Text'

const StyledIcon = styled(Icon)`
  position: absolute;
  bottom: 0;
  left: 0px;
  opacity: 0.5;
`

const ValueCard = ({ title, description, icon }) => (
  <Flex
    data-aos="fade-up"
    data-aos-duration="1000"
    flexDirection="column"
    justifyContent="start"
    flexGrow="1"
    maxWidth={['100%', '30%', '30%', '18%']}
    bg="white"
    px={3}
    py={5}
    pt={7}
    m={2}
    position="relative"
  >
    <StyledIcon icon={icon} height="140px" color="#7BE2D9" />
    <Text
      position="relative"
      zIndex="2"
      textAlign="center"
      fontSize={4}
      as="h4"
      mb={3}
    >
      {title}
    </Text>
    <Text position="relative" zIndex="2" textAlign="center" variant="body">
      {description}
    </Text>
  </Flex>
)

export default ValueCard
