import React from 'react'

import { Box, Container, Flex } from '@components/Grid'
import { SectionTitle, Text } from '@components/Text'
import Image from './Image'

const OurServices = ({
  TitleSection,
  title,
  body1,
  body2,
  body3,
  title2,
  body4,
  cita,
  title3,
}) => (
  <Box as="section" bg="blue.500" py={9} id="services">
    <Container>
      <SectionTitle color="primary.900">
{TitleSection}</SectionTitle>
      <Flex
        flexDirection={[
          'column-reverse',
          'column-reverse',
          'column-reverse',
          'row',
        ]}
      >
        <Box
          data-aos="fade-down"
          bg="white"
          position="relative"
          px={4}
          py={6}
          left={[0, 0, 0, 4]}
          top={[0, 0, 0, 4]}
          zIndex="2"
          width="100%"
        >
          <Text variant="body">{body1}</Text>
          <Text as="h3" color="primary.900">
            {title}
          </Text>
          <Text variant="body">{body2}</Text>
          <Text as="h3" color="primary.900">
            {title2}
          </Text>
          <Text variant="body">{body3}</Text>
          <Text as="h3" color="primary.900">
            {title3}
          </Text>
          <Text variant="body">{body4}</Text>
          <Text as="i">{cita}</Text>
        </Box>
        <Box position="relative" right={[0, 0, 0, 4]} width="100%" flexGrow="1">
          <Image />
        </Box>
      </Flex>
    </Container>
  </Box>
)

export default OurServices
