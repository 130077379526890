import React from 'react'
import crosshairIcon from '@iconify/icons-uil/crosshair'
import eyeIcon from '@iconify/icons-uil/eye'

import { Flex, Box, Container } from '@components/Grid'
import { SectionTitle, Text } from '@components/Text'
import ValueCard from './ValueCard'

const WhoWeAre = ({ TitleSection,titleM, titleV, mision, mision2, vision, vision2 }) => (
  <Box
    as="section"
    bg="primary.900"
    color="white"
    pt={4}
    pb={[4, 10]}
    id="about"
  >
    <Container>
      <SectionTitle>{TitleSection}</SectionTitle>
      <Flex maxWidth="960px" mx="auto" flexDirection={['column', 'row']}>
        <ValueCard
          dataAos="fade-right"
          icon={crosshairIcon}
          title={titleM}
          description={
            <>
              <Text variant="body">{mision}</Text>
              <Text variant="body">{mision2}</Text>
            </>
          }
          bgColor="blue.500"
          color="black"
          iconColor="primary.900"
          pr={['auto', 7]}
          left={['24px', 'auto']}
        />
        <ValueCard
          dataAos="fade-left"
          icon={eyeIcon}
          title={titleV}
          bgColor="white"
          color="black"
          iconColor="primary.900"
          top={['-24px', 7]}
          left={['-24px', '-32px']}
          description={
            <>
              <Text variant="body">{vision}</Text>
              <Text variant="body">{vision2}</Text>
            </>
          }
        />
      </Flex>
    </Container>
  </Box>
)

export default WhoWeAre
