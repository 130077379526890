/* eslint-disable react/self-closing-comp */
import React from 'react'
import styled from '@style'
import { Controller, Scene } from 'react-scrollmagic'
import envelopeIcon from '@iconify/icons-uil/envelope'
import bullseyeIcon from '@iconify/icons-uil/bullseye'
import sitemapIcon from '@iconify/icons-uil/sitemap'
import suitcaseIcon from '@iconify/icons-uil/suitcase'
import tachometerFastAlt from '@iconify/icons-uil/tachometer-fast-alt'
import swatchbookIcon from '@iconify/icons-uil/swatchbook'

import { Box, Container } from '@components/Grid'
import { SectionTitle, Text } from '@components/Text'
import HoverCard from './HoverCard'

const StyledController = styled(Controller)`
  display: flex;
  flex-wrap: wrap;
  justify-content: stretch;
`

const WhatMakeUsDifferent = () => {
  return (
    <>
      <Box as="section" id="trigger1" display={['block', 'none']}>
        <Container>
          <SectionTitle mt={4} color="primary.800">
            Lo que nos hace diferentes
          </SectionTitle>
          <StyledController>
            <Scene
              classToggle="open"
              triggerElement="#trigger1"
              duration={300}
              offset={100}
            >
              {() => (
                <HoverCard
                  icon={envelopeIcon}
                  title="Nuestra visión"
                  description="Ser la firma de referencia para la gestión 360 de las finanzas de nuestros clientes."
                  bgColor="blue.500"
                ></HoverCard>
              )}
            </Scene>
            <Scene
              classToggle="open"
              triggerElement="#trigger1"
              duration={300}
              offset={500}
            >
              {() => (
                <HoverCard
                  icon={bullseyeIcon}
                  title="Nuestro objetivo"
                  description="Guiamos y acompañamos a nuestros clientes durante todas las etapas que recorren a lo largo de su vida. Llevar a la acción propuestas de valor es nuestra máxima para lograr siempre la mejor solución. "
                  bgColor="primary.900"
                ></HoverCard>
              )}
            </Scene>
            <Scene
              classToggle="open"
              triggerElement="#trigger1"
              duration={300}
              offset={1000}
            >
              {() => (
                <HoverCard
                  icon={sitemapIcon}
                  title="Carrera profesional en Aibrok "
                  description="Nuestros CLIENTES son el centro de nuestro negocio y nuestro EQUIPO el activo más valioso de la compañía. Únete a nuestro equipo de expertos en gestión y ayúdales a alcanzar sus objetivos."
                  bgColor="blue.500"
                ></HoverCard>
              )}
            </Scene>
            <Scene
              classToggle="open"
              triggerElement="#trigger1"
              duration={300}
              offset={1500}
            >
              {() => (
                <HoverCard
                  icon={suitcaseIcon}
                  title="Portfolio de Productos"
                  description={
                    <>
                      <Text variant="body">
                        Somos imparciales y eso nos permite presentar una amplia
                        gama de productos que cumplen con la excelencia y
                        calidad que merecen nuestros clientes. Disponemos de
                        acuerdos con proveedores financieros de primer nivel
                        para cubrir todas las necesidades.
                      </Text>
                    </>
                  }
                  bgColor="primary.900"
                ></HoverCard>
              )}
            </Scene>
            <Scene
              classToggle="open"
              triggerElement="#trigger1"
              duration={300}
              offset={1900}
            >
              {() => (
                <HoverCard
                  icon={tachometerFastAlt}
                  title="Cumplimos sueños profesionales"
                  description={
                    <>
                      <Text variant="body">
                        Descubre un mundo lleno de oportunidades, porque en
                        Aibrok no existen dos días iguales y los límites los
                        pones tú.
                      </Text>
                      <Text variant="body">
                        Infórmate de las ofertas laborales que tenemos para ti,
                        ¡y no esperes ni un día más para formar parte de nuestra
                        gran familia!
                      </Text>
                    </>
                  }
                  bgColor="blue.500"
                ></HoverCard>
              )}
            </Scene>
            <Scene
              classToggle="open"
              triggerElement="#trigger1"
              duration={300}
              offset={2400}
            >
              {() => (
                <HoverCard
                  icon={swatchbookIcon}
                  title="Todas las finanzas bajo un mismo techo."
                  description="Del seguro que mejor se adapte a tu necesidad, pasando por una organización financiera para tu merecida jubilación, hasta una estrategia de crecimiento de patrimonio. Nuestro abanico de servicios es tan colorido y diverso como nuestros clientes. Obtén una visión de nuestra oferta  y las distintas soluciones, así como muchos consejos útiles."
                  bgColor="primary.900"
                ></HoverCard>
              )}
            </Scene>
          </StyledController>
        </Container>
      </Box>
      <Box as="section" id="trigger1" display={['none', 'block']}>
        <Container>
          <SectionTitle mt={4} color="primary.800">
            Lo que nos hace diferentes
          </SectionTitle>
          <>
            <HoverCard
              icon={envelopeIcon}
              title="Nuestra visión"
              description="Ser la firma de referencia para la gestión 360 de las finanzas de nuestros clientes."
              bgColor="blue.500"
            ></HoverCard>

            <HoverCard
              icon={bullseyeIcon}
              title="Nuestro objetivo"
              description="Guiamos y acompañamos a nuestros clientes durante todas las etapas que recorren a lo largo de su vida. Llevar a la acción propuestas de valor es nuestra máxima para lograr siempre la mejor solución. "
              bgColor="primary.900"
            ></HoverCard>

            <HoverCard
              icon={sitemapIcon}
              title="Carrera profesional en Aibrok "
              description="Nuestros CLIENTES son el centro de nuestro negocio y nuestro EQUIPO el activo más valioso de la compañía. Únete a nuestro equipo de expertos en gestión y ayúdales a alcanzar sus objetivos."
              bgColor="blue.500"
            ></HoverCard>

            <HoverCard
              icon={suitcaseIcon}
              title="Portfolio de Productos"
              description={
                <>
                  <Text variant="body">
                    Somos imparciales y eso nos permite presentar una amplia
                    gama de productos que cumplen con la excelencia y calidad
                    que merecen nuestros clientes. Disponemos de acuerdos con
                    proveedores financieros de primer nivel para cubrir todas
                    las necesidades.
                  </Text>
                </>
              }
              bgColor="primary.900"
            ></HoverCard>

            <HoverCard
              icon={tachometerFastAlt}
              title="Cumplimos sueños profesionales"
              description={
                <>
                  <Text variant="body">
                    Descubre un mundo lleno de oportunidades, porque en Aibrok
                    no existen dos días iguales y los límites los pones tú.
                  </Text>
                  <Text variant="body">
                    Infórmate de las ofertas laborales que tenemos para ti, ¡y
                    no esperes ni un día más para formar parte de nuestra gran
                    familia!
                  </Text>
                </>
              }
              bgColor="blue.500"
            ></HoverCard>

            <HoverCard
              icon={swatchbookIcon}
              title="Todas las finanzas bajo un mismo techo"
              description="Del seguro que mejor se adapte a su necesidad, pasando por una organización financiera para su merecida jubilación, hasta una estrategia de crecimiento de patrimonio. Nuestro abanico  de servicios es tan colorido y diverso como nuestros clientes. Obtenga una visión de nuestra oferta  y las distintas soluciones, así como muchos consejos útiles."
              bgColor="primary.900"
            ></HoverCard>
          </>
        </Container>
      </Box>
    </>
  )
}

export default WhatMakeUsDifferent
