import React from 'react'

import { Flex, Container, Box } from '@components/Grid'
import { Text } from '@components/Text'
import HeroWrapper from './HeroWrapper'
import styled from 'styled-components'

const HeroCont = styled(Box)`
  position:relative;
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
      min-height: 100vh;
    min-height: calc(100vh - 224px);
  }
`
const Hero = ({ title, body }) => (
  <HeroCont as="section" bg="primary.900" position="relative">
    <HeroWrapper as="section" bg="primary.900" id="hero"></HeroWrapper>
    <Container minHeight="inherit">
      <Flex
        data-aos="fade-right"
        flexDirection="column"
        justifyContent={['start', 'center']}
        alignItems="start"
        minHeight={['80vh', 'inherit']}
      >
        <Box px={5} py={6} bg="blue.500" maxWidth="600px" color="primary.800">
          <Text mb={3} variant="h1">
            <strong> {title}</strong>
          </Text>
          <Text variant="h3">{body}</Text>
        </Box>
      </Flex>
    </Container>
  </HeroCont>
)

export default Hero
