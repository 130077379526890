import React from 'react'

import { Box, Container } from '@components/Grid'
import { SectionTitle, Text } from '@components/Text'
import Image from './Image'

const CareerOpportunities = ({
  TitleSection,
  title,
  body1,
  body2,
  body3,
  title2,
  body4,
}) => (
  <Box as="section" py={6}>
    <Container>
      <SectionTitle color="primary.900">{TitleSection}</SectionTitle>
      <Box position="relative" overflow="hidden">
        <Box
          position={['relative', 'relative', 'relative', 'absolute']}
          zIndex="-1"
          width={['100%', '100%', '100%', '70vw']}
        >
          <Image />
        </Box>
        <Box
          data-aos="fade-down"
          ml="auto"
          my={[0, 0, 0, 3]}
          maxWidth={['100%', '100%', '100%', '600px']}
          p={4}
          bg="blue.500"
        >
          <Text as="h3" color="primary.900">
            {title}
          </Text>
          <Text variant="body">{body1}</Text>
          <Text variant="body">{body2}</Text>
          <Text variant="body">{body3}</Text>
          <Text as="h3" color="primary.900">
            {title2}
          </Text>
          <Text variant="body">{body4}</Text>
        </Box>
      </Box>
    </Container>
  </Box>
)

export default CareerOpportunities
