import React from 'react'
import usersIcon from '@iconify/icons-feather/users'
import compressArrows from '@iconify/icons-uil/compress-arrows'
import pathfinderUnite from '@iconify/icons-uil/pathfinder-unite'
import arrowGrowth from '@iconify/icons-uil/arrow-growth'
import commentsAlt from '@iconify/icons-uil/comments-alt'

import { Box, Flex, Container } from '@components/Grid'
import { SectionTitle } from '@components/Text'
import ValueCard from './ValueCard'

const OurValues = () => (
  <Box as="section" bg="primary.900" py={4}>
    <Container>
      <SectionTitle color="white">
        Se Aibrok: El ADN de nuestro equipo
      </SectionTitle>
      <Flex flexWrap="wrap" justifyContent="center">
        <ValueCard
          title="Sentido de pertenencia"
          description="Creemos en las personas que asumen sus responsabilidades, trabajan con pasión y dan ejemplo, celebran sus logros y aprenden de los errores para mejorar."
          icon={usersIcon}
        />
        <ValueCard
          title="Integridad"
          description="Actuamos de forma responsable y transparente, tanto con nuestros empleados como con nuestros clientes."
          icon={compressArrows}
        />
        <ValueCard
          title="Unión"
          description="El conjunto es más fuerte que la suma de las partes."
          icon={pathfinderUnite}
        />
        <ValueCard
          title="Afán de superación"
          description="Crecemos con valentía, innovando y buscando siempre formas de mejorar, superando los retos con disciplina, humildad y sencillez."
          icon={arrowGrowth}
        />
        <ValueCard
          title="Respeto"
          description="Hacia las personas, favoreciendo el diálogo creando un entorno de confianza en el que todos los puntos de vista sean tomados en cuenta, porque la diversidad de opiniones genera mejores soluciones."
          icon={commentsAlt}
        />
      </Flex>
    </Container>
  </Box>
)

export default OurValues
