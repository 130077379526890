import styled from 'styled-components'

import { Box } from '@components/Grid'
import BgImg from '@images/home_hero_cityscape.png'

const HeroWrapper = styled(Box)`
  background-image: url(${BgImg});
  background-image: linear-gradient(
      to bottom,
      ${(props) => props.theme.colors.primary[900]} 0%,
      ${(props) => props.theme.colors.primary[900]} 20%,
      rgba(250, 250, 250, 0) 20%
    ),
    url(${BgImg});
    position:absolute;
    width 100vw;
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    background-image: url(${BgImg});
      background-size: cover;
      min-height: 100vh;
      width 80vw;
      right:0;
    min-height: calc(100vh - 224px);
    border: 32px solid ${(props) => props.theme.colors.primary[900]};
    border-top: none;
  }
`

export default HeroWrapper
