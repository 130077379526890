/* eslint-disable react/prop-types */
import React from 'react'
import { Icon } from '@iconify/react'

import { Flex } from '@components/Grid'
import { Text } from '@components/Text'

const ValueCard = ({
  title,
  dataAos,
  description,
  icon,
  bgColor,
  color,
  iconColor,
  pr,
  top,
  left,
}) => (
  <Flex
    data-aos={dataAos}
    bg={bgColor}
    flexDirection="column"
    p={4}
    maxWidth={['100%', '50%']}
    flexGrow="1"
    position="relative"
    color={iconColor}
    pr={pr}
    top={top}
    left={left}
  >
    <Icon icon={icon} width="110px" />
    <Text color={color} as="h3">
      {title}
    </Text>
    <Text as="div" color={color}>
      {description}
    </Text>
  </Flex>
)

export default ValueCard
