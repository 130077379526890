import React from 'react'
import styled from '@style'

import { Box } from '@components/Grid'
import BgImage from '@images/home_background-divider.png'

const StyledBackgroundDivider = styled(Box)`
  background-attachment: fixed;
`

const BackgroundDivider = () => (
  <StyledBackgroundDivider
    backgroundImage={`url(${BgImage})`}
    backgroundRepeat="no-repeat"
    backgroundPosition="center"
    backgroundSize="cover"
    height={['80vh', '50vh']}
  />
)

export default BackgroundDivider
