/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
import React from 'react'
import styled from '@style'

import { Flex, Box } from '@components/Grid'
import { Text } from '@components/Text'
import Icon from '@iconify/react'

const StyledFlex = styled(Flex)`
  transition: all 0.2s ease-in;
  background-color: ${(props) => props.theme.colors.blue[500]};
  color: ${(props) => props.theme.colors.primary[900]};
  text-align: center;
  vertical-align: top;
  & svg {
    color: ${(props) => props.theme.colors.primary[900]};
  }
  & hr {
    background: ${(props) => props.theme.colors.primary[900]};
    width: 50%;
    margin: 16px 0;
  }
  &:nth-child(odd) {
    background-color: ${(props) => props.theme.colors.primary[900]};
    color: ${(props) => props.theme.colors.white};
    & svg {
      color: ${(props) => props.theme.colors.white};
    }
    & hr {
      background: ${(props) => props.theme.colors.white};
      width: 50%;
    }
  }
  div {
    transition: all 0.2s ease-in;
    height: 0px;
    visibility: hidden;
  }
  svg {
    transition: all 0.2s ease-in;
  }
  &:hover,
  &.open {
    background: white;
    color: black;
    & svg {
      height: 48px;
      color: black;
    }
    div {
      height: initial;
      visibility: visible;
    }
  }
`

const HoverCard = React.forwardRef((props, ref) => {
  return (
    <StyledFlex
      ref={ref}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="440px"
      width={['100%', '100%', '33.33%']}
      flexGrow="1"
      p={5}
      display="inline-flex"
    >
      <Icon icon={props.icon} height="48px" />
      <Text as="h4" mb={3} fontSize={4}>
        {props.title}
      </Text>
      <Box>{props.description}</Box>
    </StyledFlex>
  )
})

HoverCard.displayName = 'HoverCard'

export default HoverCard
